// reducer.js

// Importing action types related to fetching Continue Watching
import {
  FETCH_CONTINUEWATCHING_REQUEST,
  FETCH_CONTINUEWATCHING_SUCCESS,
  FETCH_CONTINUEWATCHING_FAILURE,
} from "../../actions/continueWatchingActions/viewActions";

// Define the initial state for the reducer
const initialState = {
  continuewatchingView: [], // Array to store items for viewing
  isLoading: false,         // Initial value for isLoading is false
  error: null,              // Initial value for error is null
};

// Define the getViewContinueWatchingReducers function
const getViewContinueWatchingReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTINUEWATCHING_REQUEST:
      return {
        ...state,
        isLoading: true, // Set isLoading to true for fetch request
      };
    case FETCH_CONTINUEWATCHING_SUCCESS:
      return {
        ...state,
        continuewatchingView: action.payload, // Update continuewatchingView array with payload data
        isLoading: false, // Set isLoading to false after successful fetch
        error: null,      // Reset error to null after successful fetch
      };
    case FETCH_CONTINUEWATCHING_FAILURE:
      return {
        ...state,
        continuewatchingView: [], // Clear continuewatchingView array on failure
        isLoading: false,         // Set isLoading to false after fetch failure
        error: action.payload,    // Set error to the error payload
      };
    default:
      return state;
  }
};

export default getViewContinueWatchingReducers;
