// Importing action types for token actions
import {
  FETCH_TOKEN_REQUEST,   // Action type for requesting a token
  FETCH_TOKEN_SUCCESS,   // Action type for successful token retrieval
  FETCH_TOKEN_FAILURE,   // Action type for token retrieval failure
} from "../../actions/getTokenActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  tokenData: [],    // Initial value for tokenData is an empty array
  isLoading: false, // Initial value for isLoading is false
  isError: null,    // Initial value for isError is null
};

// Define the getTokenreducer function
const getTokenreducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_TOKEN_REQUEST:
      // When the FETCH_TOKEN_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,  // Set isLoading to true
      };
    case FETCH_TOKEN_SUCCESS:
      // When the FETCH_TOKEN_SUCCESS action is dispatched
      return {
        ...state,
        isLoading: false,          // Set isLoading to false
        tokenData: action.payload, // Update tokenData with the payload from the action
      };
    case FETCH_TOKEN_FAILURE:
      // When the FETCH_TOKEN_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,     // Set isLoading to false
        isError: action.payload,  // Update isError with the error from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getTokenreducer as the default export
export default getTokenreducer;
