// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { LANGUAGECHANGE } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_LANGUAGECHANGE_REQUEST,
  fetchlanguageChangeSuccess,
  fetchlanguageChangeFailure,
} from "../../actions/languageChangeActions";

function* fecthLanguageChange(action) {
  try {
    const languageFormData = new FormData();
    languageFormData.append("language_code", action.payload.language_code);
    const response = yield call(axios.post, LANGUAGECHANGE, languageFormData, {
      headers: {
        token: localStorage.getItem("token"),

      },
    });

    if (response.status === 201 && response.data.status === "Success") {
      yield put(fetchlanguageChangeSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchlanguageChangeFailure(error.message));
  }
}

export function* LanguagehangeSaga() {
  yield takeLatest(FETCH_LANGUAGECHANGE_REQUEST, fecthLanguageChange);
}
export default LanguagehangeSaga;
