// Import the action types for language change actions
import {
  FETCH_LANGUAGECHANGE_REQUEST,    // Action type for requesting language change
  FETCH_LANGUAGECHANGE_SUCCESS,    // Action type for successful language change
  FETCH_LANGUAGECHANGE_FAILURE,    // Action type for language change failure
} from "../../actions/languageChangeActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  languageChangeData: {}, // Initial value for languageChangeData is an empty object
  isLoading: false,       // Initial value for isLoading is false
  isError: null,          // Initial value for isError is null
};

// Define the languageChangeReducers function
const languageChangeReducers = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_LANGUAGECHANGE_REQUEST:
      // When the FETCH_LANGUAGECHANGE_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,    // Set isLoading to true
        isError: null,      // Reset isError to null
      };
    case FETCH_LANGUAGECHANGE_SUCCESS:
      // When the FETCH_LANGUAGECHANGE_SUCCESS action is dispatched
      return {
        ...state,
        languageChangeData: action.payload,   // Update languageChangeData with the payload from the action
        isLoading: false,                     // Set isLoading to false
        isError: null,                        // Reset isError to null
      };
    case FETCH_LANGUAGECHANGE_FAILURE:
      // When the FETCH_LANGUAGECHANGE_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,                     // Set isLoading to false
        isError: action.payload,              // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the languageChangeReducers as the default export
export default languageChangeReducers;
