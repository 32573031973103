import React, { createContext, useState } from "react";

// Create the Modal context
const ModalContext = createContext();

// Create the Modal Provider
const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalCustom, setModalCustom] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setCustomModal = (customValue) => {
    setModalCustom(customValue);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        isClose: closeModal,
        modalCustom,
        openModal,
        setCustomModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
