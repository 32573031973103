// Action Types
export const FETCH_PROFILE_DETAILS_REQUEST = "FETCH_PROFILE_DETAILS_REQUEST";
export const FETCH_PROFILE_DETAILS_SUCCESS = "FETCH_PROFILE_DETAILS_SUCCESS";
export const FETCH_PROFILE_DETAILS_FAILURE = "FETCH_PROFILE_DETAILS_FAILURE";

// Action Creators
// Action creator for profile details fetching request
export const fetchProfileDetailsRequest = () => ({
  type: FETCH_PROFILE_DETAILS_REQUEST,
});

// Action creator for profile details fetching success
export const fetchProfileDetailsSuccess = (profileDetails) => ({
  type: FETCH_PROFILE_DETAILS_SUCCESS,
  payload: profileDetails,
});

// Action creator for profile details fetching failure
export const fetchProfileDetailsFailure = (error) => ({
  type: FETCH_PROFILE_DETAILS_FAILURE,
  payload: error,
});
