// Importing action types for OTP verification actions
import {
  VERIFY_OTP_REQUEST,   // Action type for requesting OTP verification
  VERIFY_OTP_SUCCESS,   // Action type for successful OTP verification
  VERIFY_OTP_FAILURE,   // Action type for OTP verification failure
} from "../../actions/getVerifyOtpActions";  // Importing action types from the respective action file

// Define the initial state for the reducer
const initialState = {
  isAuth: false,         // Initial value for isAuth is false
  isLoading: false,      // Initial value for isLoading is false
  verifyOTPdata: {},     // Initial value for verifyOTPdata is an empty object
  isError: null,         // Initial value for isError is null
};

// Define the getVerifyOtpReducers function
const getVerifyOtpReducers = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      // When the VERIFY_OTP_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,    // Set isLoading to true
        verifyOTPdata: null,  // Reset verifyOTPdata to null
        isError: null,       // Reset isError to null
      };
    case VERIFY_OTP_SUCCESS:
      // When the VERIFY_OTP_SUCCESS action is dispatched
      return {
        ...state,
        isAuth: true,               // Set isAuth to true
        isLoading: false,           // Set isLoading to false
        verifyOTPdata: action.payload.data,  // Update verifyOTPdata with the payload from the action
        isError: null,              // Reset isError to null
      };
    case VERIFY_OTP_FAILURE:
      // When the VERIFY_OTP_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,          // Set isLoading to false
        isError: action.error,     // Update isError with the error from the action
        verifyOTPdata: null,       // Reset verifyOTPdata to null
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getVerifyOtpReducers as the default export
export default getVerifyOtpReducers;
