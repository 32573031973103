// sagas.js

import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { REGION } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  FETCH_REGION_REQUEST, // Import action type for fetching region data
  fetchRegionSuccess, // Import action creator for successful fetch
  fetchRegionFailure, // Import action creator for fetch failure
} from "../../actions/regionActions"; // Import action creators for success and failure cases

function* fecthRegion(action) {
  try {
    const regionFormData = new FormData(); // Create a new FormData object for region data
    regionFormData.append("tag", action.payload.tag); // Append tag to the FormData

    // Make POST request to the REGION endpoint with regionFormData
    const response = yield call(axios.post, REGION, regionFormData, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fetchRegionSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fetchRegionFailure(error.message));
  }
}

// Saga watcher function to listen for FETCH_REGION_REQUEST action
export function* RegionSaga() {
  yield takeLatest(FETCH_REGION_REQUEST, fecthRegion);
}

// Export the saga watcher function as default
export default RegionSaga;
