// reducers.js

import {
  FETCH_TRANSACTIONHISTORY_REQUEST, // Import action type for requesting transaction history data
  FETCH_TRANSACTIONHISTORY_SUCCESS, // Import action type for successful transaction history data fetch
  FETCH_TRANSACTIONHISTORY_FAILURE, // Import action type for transaction history data fetch failure
} from "../../actions/transactionHistoryActions"; // Import action types

// Initial state for the transaction history data reducer
const initialState = {
  transactionData: null, // Initial transaction history data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle transaction history data
const transactionHistoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONHISTORY_REQUEST:
      // When requesting transaction history data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_TRANSACTIONHISTORY_SUCCESS:
      // When transaction history data fetch is successful, update transactionData, set isLoading to false, and reset isError
      return {
        ...state,
        transactionData: action.payload, // Update transaction history data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_TRANSACTIONHISTORY_FAILURE:
      // When transaction history data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default transactionHistoryReducers;
