// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { VIEWALL } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_VIEWALL_REQUEST,
  fetchViewAllSuccess,
  fetchViewAllFailure,
} from "../../actions/getViewallActions";

function* fecthViewAll(action) {
  try {
    const viewallpayload = new FormData();
    console.log('img_type', action.payload)
    // img_type: w
    // categoryid: 187
    // page: 1
    viewallpayload.append("img_type", 'w');
    viewallpayload.append("categoryid", action.payload.categoryid);
    viewallpayload.append("page", action.payload.page);

    const response = yield call(axios.post, VIEWALL, viewallpayload, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchViewAllSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchViewAllFailure(error.message));
  }
}

export function* GetViewAllSaga() {
  yield takeLatest(FETCH_VIEWALL_REQUEST, fecthViewAll);
}
export default GetViewAllSaga;
