// sagas.js

import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { PREHOMEPOPUP } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  FETCH_PREHOMEPOPUP_REQUEST, // Import action type for fetching pre-home popup
  fecthPreHomePopupSuccess, // Import action creator for successful fetch
  fecthPreHomePopupFailure, // Import action creator for fetch failure
} from "../../actions/preHomePopupActions"; // Import action creators for success and failure cases

// Saga function to fetch pre-home popup data
function* fecthPreHomePopupApi(action) {
  try {
    // Create a new FormData object for pre-home popup payload
    const prehomepopupPayload = new FormData();
    prehomepopupPayload.append("img_type", "w"); // Append image type to the FormData
    prehomepopupPayload.append("category_id", action.payload.categoryid); // Append category ID to the FormData
    prehomepopupPayload.append("page", action.payload.page); // Append page number to the FormData

    // Make POST request to the PREHOMEPOPUP endpoint with prehomepopupPayload
    const response = yield call(axios.post, PREHOMEPOPUP, prehomepopupPayload, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful fetch with response data
      yield put(fecthPreHomePopupSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle fetch failure with error message
    yield put(fecthPreHomePopupFailure(error.message));
  }
}

// Watcher saga to trigger the fetch pre-home popup request
export function* preHomePopupSaga() {
  yield takeLatest(FETCH_PREHOMEPOPUP_REQUEST, fecthPreHomePopupApi);
}

// Export the watcher saga function as default
export default preHomePopupSaga;
