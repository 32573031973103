// reducers.js

import {
  FETCH_REGION_REQUEST, // Import action type for requesting region data
  FETCH_REGION_SUCCESS, // Import action type for successful region data fetch
  FETCH_REGION_FAILURE, // Import action type for region data fetch failure
} from "../../actions/regionActions"; // Import action types

// Initial state for the region data reducer
const initialState = {
  regionData: null, // Initial region data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle region data
const RegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGION_REQUEST:
      // When requesting region data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_REGION_SUCCESS:
      // When region data fetch is successful, update regionData, set isLoading to false, and reset isError
      return {
        ...state,
        regionData: action.payload, // Update region data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_REGION_FAILURE:
      // When region data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default RegionReducer;
