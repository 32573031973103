// actions.js
// Action types for transaction history fetching request, success, and failure
export const FETCH_TRANSACTIONHISTORY_REQUEST = "FETCH_TRANSACTIONHISTORY_REQUEST";
export const FETCH_TRANSACTIONHISTORY_SUCCESS = "FETCH_TRANSACTIONHISTORY_SUCCESS";
export const FETCH_TRANSACTIONHISTORY_FAILURE = "FETCH_TRANSACTIONHISTORY_FAILURE";

// Action creator for transaction history fetching request
export const fetchTransactionHistoryRequest = (payload) => ({
  type: FETCH_TRANSACTIONHISTORY_REQUEST,
  payload,
});

// Action creator for transaction history fetching success
export const fetchTransactionHistorySuccess = (transactionData) => ({
  type: FETCH_TRANSACTIONHISTORY_SUCCESS,
  payload: transactionData,
});

// Action creator for transaction history fetching failure
export const fetchTransactionHistoryFailure = (error) => ({
  type: FETCH_TRANSACTIONHISTORY_FAILURE,
  payload: error,
});
