// Import the required functions from the Firebase SDK
import { initializeApp } from "firebase/app"; // Imports the initializeApp function from the firebase/app module
import { getMessaging, getToken } from "firebase/messaging"; // Imports the getMessaging and getToken functions from the firebase/messaging module

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAkn0cVO3tI7SVw7PX6Wgv796NqNEr5etw",
    authDomain: "nn-plus.firebaseapp.com",
    projectId: "nn-plus",
    storageBucket: "nn-plus.appspot.com",
    messagingSenderId: "416390792665",
    appId: "1:416390792665:web:88b53e11dac0a5cbf845c8",
    measurementId: "G-JMQG2WYXCX"
  };

//-----------------------------------------------firebase sdk code start-----------------------------------------------------------------//
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAkn0cVO3tI7SVw7PX6Wgv796NqNEr5etw",
//   authDomain: "nn-plus.firebaseapp.com",
//   projectId: "nn-plus",
//   storageBucket: "nn-plus.appspot.com",
//   messagingSenderId: "416390792665",
//   appId: "1:416390792665:web:88b53e11dac0a5cbf845c8",
//   measurementId: "G-JMQG2WYXCX"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
//-----------------------------------------------firebase sdk code end-----------------------------------------------------------------//

// Initialize the Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);

// Export the messaging instance for later use
export const messaging = getMessaging(app);

// Define an async function to generate a Firebase Cloud Messaging token
// export const generateToken = async () => {
//     // Request notification permission from the user
//     const permission = await Notification.requestPermission();

//     // If permission is granted
//     if (permission === "granted") {
//         console.log('permission::', permission) // Log the permission status

//         // Get the FCM token using the messaging instance and VAPID key
//         const token = await getToken(messaging, {
//             vapidKey: 'BBTGunMGZFIofVj7mHwwKGvVfQowCO8KQysF-AHkpLku-iLiT7NZMzq1roh07WBEDqha5eND4o8RwTYE703hx_c'
//         })
//         console.log('token::', token) // Log the generated token
//     } else {
//         // If permission is not granted, do nothing
//     }
// }