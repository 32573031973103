// actions.js
export const FETCH_DEVICEMANAGEMENT_REQUEST = "FETCH_DEVICEMANAGEMENT_REQUEST";
export const FETCH_DEVICEMANAGEMENT_SUCCESS = "FETCH_DEVICEMANAGEMENT_SUCCESS";
export const FETCH_DEVICEMANAGEMENT_FAILURE = "FETCH_DEVICEMANAGEMENT_FAILURE";

export const fecthDeviceManagementRequest = (payload) => ({
  type: FETCH_DEVICEMANAGEMENT_REQUEST,
  payload,
});

export const fecthDeviceManagementSuccess = (deviceData) => ({
  type: FETCH_DEVICEMANAGEMENT_SUCCESS,
  payload: deviceData,
});

export const fecthDeviceManagementFailure = (error) => ({
  type: FETCH_DEVICEMANAGEMENT_FAILURE,
  payload: error,
});
