// actions.js
// Action types for series subdata fetching request, success, and failure
export const FETCH_SERIES_SUBDATA_REQUEST = "FETCH_SERIES_SUBDATA_REQUEST";
export const FETCH_SERIES_SUBDATA_SUCCESS = "FETCH_SERIES_SUBDATA_SUCCESS";
export const FETCH_SERIES_SUBDATA_FAILURE = "FETCH_SERIES_SUBDATA_FAILURE";

// Action creator for series subdata fetching request
export const fetchSeriesSubdataRequest = (payload) => ({
  type: FETCH_SERIES_SUBDATA_REQUEST,
  payload,
});

// Action creator for series subdata fetching success
export const fetchSeriesSubdataSuccess = (seriesSubData) => ({
  type: FETCH_SERIES_SUBDATA_SUCCESS,
  payload: seriesSubData,
});

// Action creator for series subdata fetching failure
export const fetchSeriesSubdataFailure = (error) => ({
  type: FETCH_SERIES_SUBDATA_FAILURE,
  payload: error,
});
