// Import the action types for deleting items from the user's list
import {
  DELETE_MYLIST_REQUEST,   // Action type for requesting deletion
  DELETE_MYLIST_SUCCESS,   // Action type for successful deletion
  DELETE_MYLIST_FAILURE,   // Action type for deletion failure
} from "../../actions/mylistActions/deletemylistAction";

// Define the initial state for the reducer
const initialState = {
  deleteMyListData: {},  // Initial value for deleteMyListData is an empty object
  isLoading: false,      // Initial value for isLoading is false
  error: null,           // Initial value for error is null
};

// Define the getMylistDeleteReducer function
const getMylistDeleteReducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case DELETE_MYLIST_REQUEST:
      // When the DELETE_MYLIST_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,  // Set isLoading to true
      };
    case DELETE_MYLIST_SUCCESS:
      // When the DELETE_MYLIST_SUCCESS action is dispatched
      return {
        ...state,
        deleteMyListData: action.payload, // Update deleteMyListData with the payload from the action
        isLoading: false,                 // Set isLoading to false
        error: null,                      // Reset error to null
      };
    case DELETE_MYLIST_FAILURE:
      // When the DELETE_MYLIST_FAILURE action is dispatched
      return {
        ...state,
        deleteMyListData: {},    // Reset deleteMyListData to an empty object
        isLoading: false,        // Set isLoading to false
        error: action.payload,   // Update error with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getMylistDeleteReducer as the default export
export default getMylistDeleteReducer;
