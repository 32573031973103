import { combineReducers } from "redux";
// Importing other reducers...
import ipapiReducer from "./ipapiReducers"; // Importing IPAPI reducer
import getTokenReducer from "./getTokenReducers"; // Importing token reducer
// import dataReducer from "./getCommonDataReducers"; // Importing common data reducer
import getTopMenuReducer from "./topMenuReducers"; // Importing top menu reducer
import sideMenuReducer from "./sidemenuReducers"; // Importing side menu reducer
import footerMenuReducer from "./footerMenuReducers"; // Importing footer menu reducer
import getHomeReducer from "./getHomeReducers"; // Importing home reducer
import getVideoReducers from "./videoReducers"; // Importing video reducer
import generateOtpReducer from "./generateOtpReducers/"; // Importing generate OTP reducer
import getVerifyOtpReducers from "./getVerifyOtpReducers"; // Importing get verify OTP reducer
import profileDetailsReducer from "./profileReducer"; // Importing profile details reducer
import updatedProfileReducers from "./updateProfileReducers"; // Importing updated profile reducer
import imageUploadReducers from "./imageUploadReducers"; // Importing image upload reducer
import getRelatedVideos from "./relatedVideoReducers"; // Importing related videos reducer
import EpisodeReducer from "./episodeReducers"; // Importing episode reducer
import DeviceManagementReducer from "./deviceManagementReducers"; // Importing device management reducer
import RemoveDeviceManagementReducer from "./removeDeviceManagementReducers"; // Importing remove device management reducer
import RecommendedReducer from "./recommendedReducers"; // Importing recommended reducer
import getHomeCarousel from "./homeCarouselReducers"; // Importing home carousel reducer
import getViewContinueWatchingReducers from "./continueWatchingReducers/viewReducers"; // Importing view continue watching reducer
import getDeleteContinueWatchingReducers from "./continueWatchingReducers/deleteReducers"; // Importing delete continue watching reducer
import getViewMylistReducers from "./mylistReducer/viewMylistreducer"; // Importing view my list reducer
import getPreHomePopUp from "./preHomePopupReducers"; // Importing pre-home popup reducer
import getLogoutReducer from "./logoutReducers"; // Importing logout reducer
import SearchReducer from "./searchReducers"; // Importing search reducer
import SearchDataReducer from "./fecthSearchDataReducers"; // Importing fetch search data reducer
import LanguagePreviewReducers from "./languagePreviewReducers"; // Importing language preview reducer
import LanguageChangeReducers from "./languageChangeReducers"; // Importing language change reducer
import staticpageReducers from "./staticpageReducers"; // Importing static page reducer
import transactionHistoryReducers from "./transactionHistoryReducers"; // Importing transaction history reducer
// import ViewAllReducer from './viewallReducers'; // Importing view all reducer
import getMylistDeleteReducer from "./mylistReducer/deleteMylistreducer"; // Importing delete my list reducer
import SeriesSubdataReducer from './seriesSubdataReducers'; // Importing sub-series data reducer
import TvscheduleReducer from './tvscheduleReducers'; // Importing TV schedule reducer
import TvscheduletimezoneReducer from './tvscheduletimezoneReducers'; // Importing TV schedule timezone reducer
import RegionReducer from "./regionReducers"; // Importing region reducer
import PrayerReducer from "./prayerReducers"; // Importing prayer reducer
import commonDataReducer from "./commonReducers";
import DynamicPagesReducer from "./dynamicPagesReducers";
import NotificationReducer from './nortificationReducers';
import GetViewAllReducer from './getViewallReducers'
// Combining all reducers into one root reducer
const rootReducer = combineReducers({
  // List of all reducers
  getuserLocation: ipapiReducer, // IPAPI reducer
  getToken: getTokenReducer, // Token reducer
  menu: getTopMenuReducer, // Top menu reducer
  sideMenu: sideMenuReducer, // Side menu reducer
  footerMenu: footerMenuReducer, // Footer menu reducer
  home: getHomeReducer, // Home reducer
  viewMylist: getViewMylistReducers, // View my list reducer
  deleteMylist: getMylistDeleteReducer, // Delete my list reducer
  video: getVideoReducers, // Video reducer
  relatedVideos: getRelatedVideos, // Related videos reducer
  episodeVideos: EpisodeReducer, // Episode reducer
  recommendedVideos: RecommendedReducer, // Recommended reducer
  homeCarousel: getHomeCarousel, // Home carousel reducer
  // data: dataReducer, // Common data reducer
  generateOtp: generateOtpReducer, // Generate OTP reducer
  getVerifyOtp: getVerifyOtpReducers, // Get verify OTP reducer
  profileDetails: profileDetailsReducer, // Profile details reducer
  profileUpdate: updatedProfileReducers, // Updated profile reducer
  imageUpload: imageUploadReducers, // Image upload reducer
  continueWatchingview: getViewContinueWatchingReducers, // View continue watching reducer
  continueWatchingdelete: getDeleteContinueWatchingReducers, // Delete continue watching reducer
  prehomepopup: getPreHomePopUp, // Pre-home popup reducer
  transactionhistory: transactionHistoryReducers, // Transaction history reducer
  logout: getLogoutReducer, // Logout reducer
  static: staticpageReducers, // Static page reducer
  search: SearchReducer, // Search reducer
  searchData: SearchDataReducer, // Search data reducer
  // viewall: ViewAllReducer, // View all reducer
  languages: LanguagePreviewReducers, // Language preview reducer
  languagechange: LanguageChangeReducers, // Language change reducer
  DeviceManagement: DeviceManagementReducer, // Device management reducer
  seriesData: SeriesSubdataReducer, // Sub-series data reducer
  tvschedule: TvscheduleReducer, // TV schedule reducer
  tvscheduleTimezone: TvscheduletimezoneReducer, // TV schedule timezone reducer
  RemoveDeviceManagement: RemoveDeviceManagementReducer, // Remove device management reducer
  regions: RegionReducer, // Region reducer
  prayer: PrayerReducer, // Prayer reducer
  common: commonDataReducer,// common reducer
  dynamic: DynamicPagesReducer,
  nortification: NotificationReducer,
  getviewall: GetViewAllReducer,
});

export default rootReducer; // Exporting the root reducer
