// getTokenActions.js
// Action types for token fetching request, success, and failure
export const FETCH_TOKEN_REQUEST = 'FETCH_TOKEN_REQUEST';
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_TOKEN_FAILURE = 'FETCH_TOKEN_FAILURE';

// Action creator for token fetching request
export const fetchTokenRequest = () => ({
  type: FETCH_TOKEN_REQUEST,
});

// Action creator for token fetching success
export const fetchTokenSuccess = (token) => ({
  type: FETCH_TOKEN_SUCCESS,
  payload: token,
});

// Action creator for token fetching failure
export const fetchTokenFailure = (error) => ({
  type: FETCH_TOKEN_FAILURE,
  payload: error,
});
