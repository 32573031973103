// actions.js
// Action types for footer menu fetching request, success, and failure
export const FETCH_FOOTER_MENU_REQUEST = "FETCH_FOOTER_MENU_REQUEST";
export const FETCH_FOOTER_MENU_SUCCESS = "FETCH_FOOTER_MENU_SUCCESS";
export const FETCH_FOOTER_MENU_FAILURE = "FETCH_FOOTER_MENU_FAILURE";

// Action creator for footer menu fetching request
export const fetchFooterMenu = (payload) => ({
  type: FETCH_FOOTER_MENU_REQUEST,
  payload,
});

// Action creator for footer menu fetching success
export const fetchFooterMenuSuccess = (data) => ({
  type: FETCH_FOOTER_MENU_SUCCESS,
  payload: data,
});

// Action creator for footer menu fetching failure
export const fetchFooterMenuFailure = (error) => ({
  type: FETCH_FOOTER_MENU_FAILURE,
  payload: error,
});
