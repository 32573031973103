// Importing action types
import {
  FETCH_PUSHNOTIFICATION_REQUEST,
  FETCH_PUSHNOTIFICATION_SUCCESS,
  FETCH_PUSHNOTIFICATION_FAILURE,
} from "../../actions/nortificationActions";

// Define the initial state for the reducer
const initialState = {
  pushnotification: null,  // Initial value for pushnotification is null
  isLoading: false,   // Initial value for isLoading is false
  isError: null,      // Initial value for isError is null
};

// Define the NotificationReducer function
const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PUSHNOTIFICATION_REQUEST:
      // When the FETCH_PUSHNOTIFICATION_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_PUSHNOTIFICATION_SUCCESS:
      // When the FETCH_PUSHNOTIFICATION_SUCCESS action is dispatched
      return {
        ...state,
        pushnotification: action.payload,  // Update pushnotification with the payload from the action
        isLoading: false,             // Set isLoading to false
        isError: null,                // Reset isError to null
      };
    case FETCH_PUSHNOTIFICATION_FAILURE:
      // When the FETCH_PUSHNOTIFICATION_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,             // Set isLoading to false
        isError: action.payload,      // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default NotificationReducer;
