// sagas.js

import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { LOGOUT } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  FETCH_LOGOUT_REQUEST, // Import action type for logout request
  fetchLogoutSuccess, // Import action creator for successful logout
  fetchLogoutFailure, // Import action creator for logout failure
} from "../../actions/logoutAction"; // Import action creators for success and failure cases

// Saga function to handle logout
function* fecthLogout(action) {
  try {
    // Create a new FormData object for logout payload
    const logoutPayload = new FormData();
    logoutPayload.append("uuid", action.payload.uuid); // Append uuid to the FormData

    // Make POST request to the LOGOUT endpoint with logoutPayload
    const response = yield call(axios.post, LOGOUT, logoutPayload, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful logout
      yield put(fetchLogoutSuccess(response.data));
    }
  } catch (error) {
    // Dispatch action to handle logout failure with error message
    yield put(fetchLogoutFailure(error.message));
  }
}

// Watcher saga to trigger the logout request
export function* LogoutSaga() {
  yield takeLatest(FETCH_LOGOUT_REQUEST, fecthLogout);
}

// Export the watcher saga function as default
export default LogoutSaga;
