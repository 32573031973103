// reducers.js

import {
  FETCH_SEARCH_REQUEST, // Import action type for requesting search data
  FETCH_SEARCH_SUCCESS, // Import action type for successful search data fetch
  FETCH_SEARCH_FAILURE, // Import action type for search data fetch failure
} from "../../actions/searchActions"; // Import action types

// Initial state for the search data reducer
const initialState = {
  searchData: null, // Initial search data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle search data
const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_REQUEST:
      // When requesting search data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_SEARCH_SUCCESS:
      // When search data fetch is successful, update searchData, set isLoading to false, and reset isError
      return {
        ...state,
        searchData: action.payload, // Update search data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_SEARCH_FAILURE:
      // When search data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default SearchReducer;
