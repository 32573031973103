// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import { MENU } from "../../../utility/constants.js";
import {
  FETCH_FOOTER_MENU_REQUEST,
  fetchFooterMenuSuccess,
  fetchFooterMenuFailure,
} from "../../actions/fecthFooterMenuActions.js";

function* fetchFooterMenuSaga(action) {
  try {
    const footerMenuPayload = new FormData();
    footerMenuPayload.append("menu_type", "wf");
    footerMenuPayload.append("device_type", 2);
    const response = yield call(axios.post, MENU, footerMenuPayload, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchFooterMenuSuccess(response.data));
    } else {
    }
  } catch (error) {
    yield put(fetchFooterMenuFailure(error.message));
  }
}

export function* watchFetchFooterMenu() {
  yield takeLatest(FETCH_FOOTER_MENU_REQUEST, fetchFooterMenuSaga);
}
export default watchFetchFooterMenu;
