// actions.js
// Action types for language preview fetching request, success, and failure
export const FETCH_LANGUAGEPREVIEW_REQUEST = "FETCH_LANGUAGEPREVIEW_REQUEST";
export const FETCH_LANGUAGEPREVIEW_SUCCESS = "FETCH_LANGUAGEPREVIEW_SUCCESS";
export const FETCH_LANGUAGEPREVIEW_FAILURE = "FETCH_LANGUAGEPREVIEW_FAILURE";

// Action creator for language preview fetching request
export const fetchLanguagePreviewRequest = (payload) => ({
  type: FETCH_LANGUAGEPREVIEW_REQUEST,
  payload,
});

// Action creator for language preview fetching success
export const fetchLanguagePreviewSuccess = (languagepreviewData) => ({
  type: FETCH_LANGUAGEPREVIEW_SUCCESS,
  payload: languagepreviewData,
});

// Action creator for language preview fetching failure
export const fetchLanguagePreviewFailure = (error) => ({
  type: FETCH_LANGUAGEPREVIEW_FAILURE,
  payload: error,
});
