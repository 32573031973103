// Define a constant string for the action type "FECTH_TVSCHEDULETIME_ZONE_REQUEST"
export const FECTH_TVSCHEDULETIME_ZONE_REQUEST = "FECTH_TVSCHEDULETIME_ZONE_REQUEST";

// Define a constant string for the action type "FECTH_TVSCHEDULETIME_ZONE_SUCCESS"
export const FECTH_TVSCHEDULETIME_ZONE_SUCCESS = "FECTH_TVSCHEDULETIME_ZONE_SUCCESS";

// Define a constant string for the action type "FECTH_TVSCHEDULETIME_ZONE_FAILURE"
export const FECTH_TVSCHEDULETIME_ZONE_FAILURE = "FECTH_TVSCHEDULETIME_ZONE_FAILURE";

// Action creator function to create an action object with type "FECTH_TVSCHEDULETIME_ZONE_REQUEST" and the provided payload
export const fecthtvscheduletimezoneRequest = (payload) => ({
  type: FECTH_TVSCHEDULETIME_ZONE_REQUEST,
  payload,
});

// Action creator function to create an action object with type "FECTH_TVSCHEDULETIME_ZONE_SUCCESS" and the provided tvscheduletimezoneData payload
export const fecthtvscheduletimezoneSuccess = (tvscheduletimezoneData) => ({
  type: FECTH_TVSCHEDULETIME_ZONE_SUCCESS,
  payload: tvscheduletimezoneData,
});

// Action creator function to create an action object with type "FECTH_TVSCHEDULETIME_ZONE_FAILURE" and the provided error payload
export const fecthtvscheduletimezoneFailure = (error) => ({
  type: FECTH_TVSCHEDULETIME_ZONE_FAILURE,
  payload: error,
});