// reducers.js

import {
  FETCH_SERIES_SUBDATA_REQUEST, // Import action type for requesting series subdata
  FETCH_SERIES_SUBDATA_SUCCESS, // Import action type for successful series subdata fetch
  FETCH_SERIES_SUBDATA_FAILURE, // Import action type for series subdata fetch failure
} from "../../actions/seriesSubdataActions"; // Import action types

// Initial state for the series subdata reducer
const initialState = {
  seriesSubData: [], // Initial series subdata is an empty array
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle series subdata
const SeriesSubdataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERIES_SUBDATA_REQUEST:
      // When requesting series subdata, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_SERIES_SUBDATA_SUCCESS:
      // When series subdata fetch is successful, update seriesSubData, set isLoading to false, and reset isError
      return {
        ...state,
        seriesSubData: action.payload, // Update series subdata with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_SERIES_SUBDATA_FAILURE:
      // When series subdata fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default SeriesSubdataReducer;
