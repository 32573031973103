// actions.js

// Define action types for fetching related videos
export const FETCH_RELATEDVIDEOS_REQUEST = "FETCH_RELATEDVIDEOS_REQUEST";
export const FETCH_RELATEDVIDEOS_SUCCESS = "FETCH_RELATEDVIDEOS_SUCCESS";
export const FETCH_RELATEDVIDEOS_FAILURE = "FETCH_RELATEDVIDEOS_FAILURE";

// Action creator for initiating the request to fetch related videos
export const fecthRelatedVideos = (payload) => ({
  type: FETCH_RELATEDVIDEOS_REQUEST, // Dispatches a request action
  payload, // Payload containing data for fetching related videos
});

// Action creator for successful fetching of related videos
export const fecthRelatedVideosSuccess = (relatedVideosData) => ({
  type: FETCH_RELATEDVIDEOS_SUCCESS, // Dispatches a success action
  payload: relatedVideosData, // Data received as a result of successful fetching
});

// Action creator for failure in fetching related videos
export const fecthRelatedVideosFailure = (error) => ({
  type: FETCH_RELATEDVIDEOS_FAILURE, // Dispatches a failure action
  payload: error, // Error message indicating the reason for failure
});
