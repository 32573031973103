// Action Types
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Action Creators
// Action creator for image upload request
export const uploadImageRequest = (imageData) => ({
  type: UPLOAD_IMAGE_REQUEST,
  payload: imageData,
});

// Action creator for image upload success
export const uploadImageSuccess = (imageUrl) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: imageUrl,
});

// Action creator for image upload failure
export const uploadImageFailure = (error) => ({
  type: UPLOAD_IMAGE_FAILURE,
  payload: error,
});
