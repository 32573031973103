// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { ACTIVElOGINS } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_DEVICEMANAGEMENT_REQUEST,
  fecthDeviceManagementSuccess,
  fecthDeviceManagementFailure,
} from "../../actions/deviceManagementActions";

function* fecthDevice(action) {
  try {
    const response = yield call(axios.get, ACTIVElOGINS, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {

      yield put(fecthDeviceManagementSuccess(response.data));
    }
  } catch (error) {
    yield put(fecthDeviceManagementFailure(error.message));
  }
}

export function* DeviceManagementSaga() {
  yield takeLatest(FETCH_DEVICEMANAGEMENT_REQUEST, fecthDevice);
}
export default DeviceManagementSaga;
