// sagas.js
import { takeLatest, put, call } from "redux-saga/effects"; // Import necessary functions from Redux Saga
import { MULTIDEVICELOGOUT } from "../../../utility/constants"; // Import constant for API endpoint
import axios from "axios"; // Import Axios for making HTTP requests
import {
  // Import action types and action creators for removing device management
  FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST,
  fecthRemoveDeviceManagementSuccess,
  fecthRemoveDeviceManagementFailure,
} from "../../actions/removeDeviceManagementActions"; // Import action creators for success and failure cases

function* fecthDevice(action) {
  try {
    let payloadData = new FormData(); // Create a new FormData object for payload data
    // Append uuid to the FormData
    // Note: This part is currently commented out in the original code
    // payloadData.append("uuid", action.payload.uuid);
    payloadData.append("uuid", 'P7CBNEQKYYOFG'); // Temporary uuid value (commented out line replaced)

    // Make POST request to the MULTIDEVICELOGOUT endpoint with payloadData
    const response = yield call(axios.post, MULTIDEVICELOGOUT, payloadData, {
      // Include token from local storage in the request headers
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // Check if the response is successful and status is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // Dispatch action to handle successful device removal with response data
      yield put(fecthRemoveDeviceManagementSuccess(response.data));
    }

  } catch (error) {
    // Dispatch action to handle device removal failure with error message or response data
    yield put(fecthRemoveDeviceManagementFailure(error.response.data));
  }
}

// Saga watcher function to listen for FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST action
export function* DeviceManagementSaga() {
  yield takeLatest(FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST, fecthDevice);
}

// Export the saga watcher function as default
export default DeviceManagementSaga;
