// Import necessary effects from the redux-saga library
import { call, put, takeLatest } from "redux-saga/effects";

// Import the FETCH_SIDEMENU_REQUEST action type and the fetchSideMenuSuccess and fetchSideMenuFailure action creators from the sidemenuActions file
import {
  FETCH_SIDEMENU_REQUEST,
  fetchSideMenuSuccess,
  fetchSideMenuFailure,
} from "../../actions/sidemenuActions";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the MENU constant from the utility/constants.js file
import { MENU } from "../../../utility/constants.js";

// Define a generator function fetchSideMenu that takes an action object as an argument
function* fetchSideMenu(action) {
  try {
    // Create a new FormData object to hold the request payload
    const sidemenuPlayload = new FormData();
    // Append the menu_type data to the FormData object
    sidemenuPlayload.append("menu_type", "wl");
    // Append the device_type data to the FormData object
    sidemenuPlayload.append("device_type", 2);

    // Use the call effect to make a POST request to the MENU endpoint with the FormData payload
    const response = yield call(axios.post, MENU, sidemenuPlayload, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fetchSideMenuSuccess action with the response data as payload
      yield put(fetchSideMenuSuccess(response.data));
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchSideMenuFailure action with the error message as payload
    yield put(fetchSideMenuFailure(error.message));
  }
}

// Define a generator function sideMenuSaga
function* sideMenuSaga() {
  // Use the takeLatest effect to listen for the FETCH_SIDEMENU_REQUEST action and run the fetchSideMenu generator function when it's dispatched
  yield takeLatest(FETCH_SIDEMENU_REQUEST, fetchSideMenu);
}

// Export the sideMenuSaga as the default export
export default sideMenuSaga;