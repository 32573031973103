import React, { useContext } from "react";
import Snackbar from "@mui/material/Snackbar"; // Importing the Snackbar component from Material-UI
import MuiAlert from "@mui/material/Alert"; // Importing the Alert component from Material-UI

import { SnackbarContext } from "./SnackbarContext"; // Importing the SnackbarContext

const SnackbarComponent = () => {
  // Using useContext hook to consume the context
  const { snackbarProps, snackbarContentProps, snackbarMessage } =
    useContext(SnackbarContext);
    

  // Defining a custom Alert component using forwardRef
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    // Rendering Snackbar with props from context
    <Snackbar {...snackbarProps}>
      {/* Rendering the custom Alert component with props from context */}
      <Alert {...snackbarContentProps}>{snackbarMessage}</Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
