// actions.js
// Action types for region fetching request, success, and failure
export const FETCH_REGION_REQUEST = "FETCH_REGION_REQUEST";
export const FETCH_REGION_SUCCESS = "FETCH_REGION_SUCCESS";
export const FETCH_REGION_FAILURE = "FETCH_REGION_FAILURE";

// Action creator for region fetching request
export const fetchRegionRequest = (payload) => ({
  type: FETCH_REGION_REQUEST,
  payload,
});

// Action creator for region fetching success
export const fetchRegionSuccess = (regionData) => ({
  type: FETCH_REGION_SUCCESS,
  payload: regionData,
});

// Action creator for region fetching failure
export const fetchRegionFailure = (error) => ({
  type: FETCH_REGION_FAILURE,
  payload: error,
});
