// updateProfileReducers.js

import {
  UPDATE_USER_PROFILE, // Import action type for updating user profile
  UPDATE_USER_PROFILE_SUCCESS, // Import action type for successful profile update
  UPDATE_USER_PROFILE_FAILURE, // Import action type for profile update failure
} from "../../actions/updateProfileActions.js"; // Import action types

// Initial state for the profile update reducer
const initialState = {
  updateProfile: null, // Initial profile update data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle profile update actions
const updatedProfileReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      // When updating user profile, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      // When profile update is successful, update updateProfile, set isLoading to false, and reset isError
      return {
        ...state,
        updateProfile: action.payload, // Update profile update data with the updated profile
        isLoading: false,
        isError: null,
      };
    case UPDATE_USER_PROFILE_FAILURE:
      // When profile update fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default updatedProfileReducers;
