// Import necessary functions and effects from the redux-saga library
import { takeLatest, call, put } from 'redux-saga/effects';

// Import the Axios library for making HTTP requests
import axios from 'axios';

// Import the TOKENAPI constant from the utility/constants.js file
import { TOKENAPI } from '../../../utility/constants.js'

// Import the FETCH_TOKEN_REQUEST action type and the fetchTokenSuccess and fetchTokenFailure action creators from the getTokenActions file
import {
  FETCH_TOKEN_REQUEST,
  fetchTokenSuccess,
  fetchTokenFailure,
} from '../../actions/getTokenActions';

// Define a generator function fetchToken
function* fetchToken() {
  // Check if there is an existing token in localStorage
  let existingToken = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token')

  try {
    // If there is no existing token
    if (!existingToken) {
      // Make a GET request to the TOKENAPI endpoint
      const response = yield call(axios.get, TOKENAPI);
      // Dispatch the fetchTokenSuccess action with the response data as payload
      yield put(fetchTokenSuccess(response.data));
    } else {
      // Do nothing if there is an existing token
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchTokenFailure action with the error message as payload
    yield put(fetchTokenFailure(error.message));
  }
}

// Define a generator function tokenSaga
export function* tokenSaga() {
  // Use the takeLatest effect to listen for the FETCH_TOKEN_REQUEST action and run the fetchToken generator function when it's dispatched
  yield takeLatest(FETCH_TOKEN_REQUEST, fetchToken);
}

// Export the tokenSaga as the default export
export default tokenSaga;