// Import the action types for logging out
import {
  FETCH_LOGOUT_REQUEST,   // Action type for requesting logout
  FETCH_LOGOUT_SUCCESS,   // Action type for successful logout
  FETCH_LOGOUT_FAILURE,   // Action type for logout failure
} from "../../actions/logoutAction";

// Define the initial state for the reducer
const initialState = {
  logoutData: null,  // Initial value for logoutData is null
  isLoading: false,  // Initial value for isLoading is false
  isError: null,     // Initial value for isError is null
};

// Define the getLogoutReducer function
const getLogoutReducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_LOGOUT_REQUEST:
      // When the FETCH_LOGOUT_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,  // Set isLoading to true
        isError: null,    // Reset isError to null
      };
    case FETCH_LOGOUT_SUCCESS:
      // When the FETCH_LOGOUT_SUCCESS action is dispatched
      return {
        ...state,
        logoutData: action.payload, // Update logoutData with the payload from the action
        isLoading: false,           // Set isLoading to false
        isError: null,              // Reset isError to null
      };
    case FETCH_LOGOUT_FAILURE:
      // When the FETCH_LOGOUT_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,      // Set isLoading to false
        isError: action.payload, // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getLogoutReducer as the default export
export default getLogoutReducer;
