// reducers.js

import {
  FETCH_PREHOMEPOPUP_REQUEST, // Import action type for requesting pre-home popup data
  FETCH_PREHOMEPOPUP_SUCCESS, // Import action type for successful pre-home popup data fetch
  FETCH_PREHOMEPOPUP_FAILURE, // Import action type for pre-home popup data fetch failure
} from "../../actions/preHomePopupActions"; // Import action types

// Initial state for the pre-home popup data reducer
const initialState = {
  preHomePopup: null, // Initial pre-home popup data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle pre-home popup data
const getPreHomePopUp = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PREHOMEPOPUP_REQUEST:
      // When requesting pre-home popup data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_PREHOMEPOPUP_SUCCESS:
      // When pre-home popup data fetch is successful, update preHomePopup, set isLoading to false, and reset isError
      return {
        ...state,
        preHomePopup: action.payload, // Update pre-home popup data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_PREHOMEPOPUP_FAILURE:
      // When pre-home popup data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default getPreHomePopUp;
