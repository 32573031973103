// actions.js
// Action types for logout request, success, and failure
export const FETCH_LOGOUT_REQUEST = "FETCH_LOGOUT_REQUEST";
export const FETCH_LOGOUT_SUCCESS = "FETCH_LOGOUT_SUCCESS";
export const FETCH_LOGOUT_FAILURE = "FETCH_LOGOUT_FAILURE";

// Action creator for logout request
export const fetchLogoutRequest = (payload) => ({
  type: FETCH_LOGOUT_REQUEST,
  payload,
});

// Action creator for logout success
export const fetchLogoutSuccess = (logoutData) => ({
  type: FETCH_LOGOUT_SUCCESS,
  payload: logoutData,
});

// Action creator for logout failure
export const fetchLogoutFailure = (error) => ({
  type: FETCH_LOGOUT_FAILURE,
  payload: error,
});
