// saga.js

import { takeLatest, put, call } from "redux-saga/effects";
import { CONTINUEWATCHING } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_CONTINUEWATCHING_REQUEST,
  fetchContinueWatchingSuccess,
  fetchContinueWatchingFailure,
} from "../../actions/continueWatchingActions/viewActions";

function* continueWatchingView(action) {
  try {
    const response = yield call(
      axios.get,
      CONTINUEWATCHING,
      {
        headers: {
          token: localStorage.getItem("token"),

        },
      }
    );

    if (response.data.status === "Success") {
      yield put(fetchContinueWatchingSuccess(response.data));
      return response.data.favourite;
    }
  } catch (error) {
    yield put(fetchContinueWatchingFailure(error.message));
  }
}

export function* viewContinueWatchingSaga() {
  yield takeLatest(FETCH_CONTINUEWATCHING_REQUEST, continueWatchingView);
}
export default viewContinueWatchingSaga;
