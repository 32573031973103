// reducers.js

import {
  FETCH_STATICPAGE_REQUEST, // Import action type for requesting static page data
  FETCH_STATICPAGE_SUCCESS, // Import action type for successful static page data fetch
  FETCH_STATICPAGE_FAILURE, // Import action type for static page data fetch failure
} from "../../actions/stacticPageActions"; // Import action types

// Initial state for the static page data reducer
const initialState = {
  staticpageData: null, // Initial static page data is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle static page data
const StaticPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATICPAGE_REQUEST:
      // When requesting static page data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_STATICPAGE_SUCCESS:
      // When static page data fetch is successful, update staticpageData, set isLoading to false, and reset isError
      return {
        ...state,
        staticpageData: action.payload, // Update static page data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_STATICPAGE_FAILURE:
      // When static page data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default StaticPageReducers;
