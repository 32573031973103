// actions.js
// Action types for OTP verification request, success, and failure
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

// Action creator for OTP verification request
export const verifyOtpRequest = (payload) => ({
  type: VERIFY_OTP_REQUEST,
  payload,
});

// Action creator for OTP verification success
export const verifyOtpSuccess = (verifyOTPdata) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: verifyOTPdata,
});

// Action creator for OTP verification failure
export const verifyOtpFailure = (error) => ({
  type: VERIFY_OTP_FAILURE,
  payload: error,
});
