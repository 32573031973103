// Import necessary functions and effects from the redux-saga library
import { takeLatest, call, put } from "redux-saga/effects";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the COMMONDATA constant from the utility/constants.js file
import { COMMONDATA } from "../../../utility/constants.js";

// Import the FETCH_COMMONDATA_REQUEST action type and the fetchCommonDataSuccess and fetchCommonDataFailure action creators from the topMenuActions file
import {
  FETCH_COMMONDATA_REQUEST,
  fetchCommonDataSuccess,
  fetchCommonDataFailure,
} from "../../actions/commonActions.js";

// Define a generator function fecthCommonData that takes an action object as an argument
function* fecthCommonData(action) {
  try {
    // Commented code for making a POST request without payload
    // const response = yield call(axios.post, COMMONDATA);
    // yield put(fetchCommonDataSuccess(response.data));

    // Create a new FormData object to hold the request payload
    const commondataPayload = new FormData();
    // Append the menu_type data to the FormData object
    // commondataPayload.append("menu_type", "hcommon");
    // Append the device_type data to the FormData object
    // commondataPayload.append("device_type", 2);

    // Make a POST request to the COMMONDATA endpoint with the FormData payload
    const response = yield call(axios.post, COMMONDATA, commondataPayload, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Commented code for checking the response status and dispatching the fetchCommonDataSuccess action
    // if (response.status === 200 && response.data.status === "Success") {
    //   yield put(fetchCommonDataSuccess(response.data));
    // } else {
    //   yield put(fetchCommonDataSuccess(response.data));
    // }

    // Dispatch the fetchCommonDataSuccess action with the response data as payload
    yield put(fetchCommonDataSuccess(response.data));
  } catch (error) {
    // Check if the error response status is 401 (Unauthorized), 400 (Bad Request), or 405 (Method Not Allowed)
    // Dispatch the fetchCommonDataFailure action with the error response data as payload
    yield put(fetchCommonDataFailure(error.response.data));
  }
}

// Define a generator function commonDataSaga
export function* commonDataSaga() {
  // Use the takeLatest effect to listen for the FETCH_COMMONDATA_REQUEST action and run the fecthCommonData generator function when it's dispatched
  yield takeLatest(FETCH_COMMONDATA_REQUEST, fecthCommonData);
}

// Export the commonDataSaga as the default export
export default commonDataSaga;