// Importing action types
import {
  DELETE_CONTINUEWATCHING_REQUEST,
  DELETE_CONTINUEWATCHING_SUCCESS,
  DELETE_CONTINUEWATCHING_FAILURE,
} from "../../actions/continueWatchingActions/deleteActions";

// Define the initial state for the reducer
const initialState = {
  continuewatchingDelete: {},  // Initial value for continuewatchingDelete is an empty object
  isLoading: false,            // Initial value for isLoading is false
  error: null,                 // Initial value for error is null
};

// Define the getDeleteContinueWatchingReducers function
const getDeleteContinueWatchingReducers = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CONTINUEWATCHING_REQUEST:
      // When the DELETE_CONTINUEWATCHING_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
      };
    case DELETE_CONTINUEWATCHING_SUCCESS:
      // When the DELETE_CONTINUEWATCHING_SUCCESS action is dispatched
      return {
        ...state,
        continuewatchingDelete: action.payload,  // Update continuewatchingDelete with the payload from the action
        isLoading: false,                        // Set isLoading to false
        error: null,                             // Reset error to null
      };
    case DELETE_CONTINUEWATCHING_FAILURE:
      // When the DELETE_CONTINUEWATCHING_FAILURE action is dispatched
      return {
        ...state,
        continuewatchingDelete: {},    // Reset continuewatchingDelete to an empty object
        isLoading: false,             // Set isLoading to false
        error: action.payload,        // Update error with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default getDeleteContinueWatchingReducers;
