// reducers.js

import {
  FETCH_RELATEDVIDEOS_REQUEST, // Import action type for requesting related videos data
  FETCH_RELATEDVIDEOS_SUCCESS, // Import action type for successful related videos data fetch
  FETCH_RELATEDVIDEOS_FAILURE, // Import action type for related videos data fetch failure
} from "../../actions/relatedVideosActions"; // Import action types

// Initial state for the related videos data reducer
const initialState = {
  relatedVideoItems: null, // Initial related video items is null
  isLoading: false, // Initial loading state is false
  isError: null, // Initial error state is null
};

// Reducer function to handle related videos data
const getRelatedVideos = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RELATEDVIDEOS_REQUEST:
      // When requesting related videos data, set isLoading to true and reset isError
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case FETCH_RELATEDVIDEOS_SUCCESS:
      // When related videos data fetch is successful, update relatedVideoData, set isLoading to false, and reset isError
      return {
        ...state,
        relatedVideoData: action.payload, // Update related videos data with the fetched data
        isLoading: false,
        isError: null,
      };
    case FETCH_RELATEDVIDEOS_FAILURE:
      // When related videos data fetch fails, set isLoading to false and update isError with the error message
      return {
        ...state,
        isLoading: false,
        isError: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default getRelatedVideos;
