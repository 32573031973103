// actions.js
// Action types for remove device management request, success, and failure
export const FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST = "FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST";
export const FETCH_REMOVE_DEVICEMANAGEMENT_SUCCESS = "FETCH_REMOVE_DEVICEMANAGEMENT_SUCCESS";
export const FETCH_REMOVE_DEVICEMANAGEMENT_FAILURE = "FETCH_REMOVE_DEVICEMANAGEMENT_FAILURE";

// Action creator for remove device management request
export const fecthRemoveDeviceManagementRequest = (payload) => ({
  type: FETCH_REMOVE_DEVICEMANAGEMENT_REQUEST,
  payload,
});

// Action creator for remove device management success
export const fecthRemoveDeviceManagementSuccess = (removeDeviceData) => ({
  type: FETCH_REMOVE_DEVICEMANAGEMENT_SUCCESS,
  payload: removeDeviceData,
});

// Action creator for remove device management failure
export const fecthRemoveDeviceManagementFailure = (error) => ({
  type: FETCH_REMOVE_DEVICEMANAGEMENT_FAILURE,
  payload: error,
});
