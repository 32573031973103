// Import necessary functions and effects from the redux-saga library
import { takeLatest, call, put } from "redux-saga/effects";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the MENU constant from the utility/constants.js file
import { MENU } from "../../../utility/constants.js";

// Import the FETCH_TOPMENU_REQUEST action type and the fetchTopMenuSuccess and fetchTopMenuFailure action creators from the topMenuActions file
import {
  FETCH_TOPMENU_REQUEST,
  fetchTopMenuSuccess,
  fetchTopMenuFailure,
} from "../../actions/topMenuActions";

// Define a generator function fetchTopMenu that takes an action object as an argument
function* fetchTopMenu(action) {
  try {
    // Commented code for making a POST request without payload
    // const response = yield call(axios.post, MENU);
    // yield put(fetchTopMenuSuccess(response.data));

    // Create a new FormData object to hold the request payload
    const topmenu = new FormData();
    // Append the menu_type data to the FormData object
    topmenu.append("menu_type", "h");
    // Append the device_type data to the FormData object
    topmenu.append("device_type", 2);

    // Make a POST request to the MENU endpoint with the FormData payload
    const response = yield call(axios.post, MENU, topmenu, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Commented code for checking the response status and dispatching the fetchTopMenuSuccess action
    // if (response.status === 200 && response.data.status === "Success") {
    //   yield put(fetchTopMenuSuccess(response.data));
    // } else {
    //   yield put(fetchTopMenuSuccess(response.data));
    // }

    // Dispatch the fetchTopMenuSuccess action with the response data as payload
    yield put(fetchTopMenuSuccess(response.data));
  } catch (error) {
    // Check if the error response status is 401 (Unauthorized), 400 (Bad Request), or 405 (Method Not Allowed)
    if (
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 405
    ) {
      // Clear the localStorage and reload the window (likely for handling token issues)
      localStorage.clear();
      window.location.reload();
    } else {
      // Dispatch the fetchTopMenuFailure action with the error response data as payload
      yield put(fetchTopMenuFailure(error.response.data));
    }
  }
}

// Define a generator function topMenuSaga
export function* topMenuSaga() {
  // Use the takeLatest effect to listen for the FETCH_TOPMENU_REQUEST action and run the fetchTopMenu generator function when it's dispatched
  yield takeLatest(FETCH_TOPMENU_REQUEST, fetchTopMenu);
}

// Export the topMenuSaga as the default export
export default topMenuSaga;