// Importing action types
import {
  FETCH_SEARCH_DATA_REQUEST,
  FETCH_SEARCH_DATA_SUCCESS,
  FETCH_SEARCH_DATA_FAILURE,
} from "../../actions/fecthSearchDataActions";

// Define the initial state for the reducer
const initialState = {
  isSearchItem: null,  // Initial value for isSearchItem is null
  isLoading: false,    // Initial value for isLoading is false
  isError: null,       // Initial value for isError is null
};

// Define the SearchDataReducer function
const SearchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_DATA_REQUEST:
      // When the FETCH_SEARCH_DATA_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_SEARCH_DATA_SUCCESS:
      // When the FETCH_SEARCH_DATA_SUCCESS action is dispatched
      return {
        ...state,
        isSearchItem: action.payload,  // Update isSearchItem with the payload from the action
        isLoading: false,              // Set isLoading to false
        isError: null,                 // Reset isError to null
      };
    case FETCH_SEARCH_DATA_FAILURE:
      // When the FETCH_SEARCH_DATA_FAILURE action is dispatched
      return {
        ...state,
        isLoading: false,              // Set isLoading to false
        isError: action.payload,       // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default SearchDataReducer;
