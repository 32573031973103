// Import the action types for fetching items from the user's list
import {
  FETCH_MYLIST_REQUEST,   // Action type for requesting the list
  FETCH_MYLIST_SUCCESS,   // Action type for successful list retrieval
  FETCH_MYLIST_FAILURE,   // Action type for list retrieval failure
} from "../../actions/mylistActions/viewmylistAction";

// Define the initial state for the reducer
const initialState = {
  viewMylistItems: [],  // Initial value for viewMylistItems is an empty array
  isLoading: false,     // Initial value for isLoading is false
  error: null,          // Initial value for error is null
};

// Define the getViewMylistReducers function
const getViewMylistReducers = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    case FETCH_MYLIST_REQUEST:
      // When the FETCH_MYLIST_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,  // Set isLoading to true
      };
    case FETCH_MYLIST_SUCCESS:
      // When the FETCH_MYLIST_SUCCESS action is dispatched
      return {
        ...state,
        viewMylistItems: action.payload, // Update viewMylistItems with the payload from the action
        isLoading: false,                // Set isLoading to false
        error: null,                     // Reset error to null
      };
    case FETCH_MYLIST_FAILURE:
      // When the FETCH_MYLIST_FAILURE action is dispatched
      return {
        ...state,
        viewMylistItems: [],   // Reset viewMylistItems to an empty array
        isLoading: false,      // Set isLoading to false
        error: action.payload, // Update error with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

// Export the getViewMylistReducers as the default export
export default getViewMylistReducers;
