// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the TRANSACTIONHISTORY constant from the utility/constants file
import { TRANSACTIONHISTORY } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the FETCH_TRANSACTIONHISTORY_REQUEST action type and the fetchTransactionHistorySuccess and fetchTransactionHistoryFailure action creators from the transactionHistoryActions file
import {
  FETCH_TRANSACTIONHISTORY_REQUEST,
  fetchTransactionHistorySuccess,
  fetchTransactionHistoryFailure,
} from "../../actions/transactionHistoryActions";

// Define a generator function fecthtransactionHistory that takes an action object as an argument
function* fecthtransactionHistory(action) {
  try {
    // Use the call effect to make a GET request to the TRANSACTIONHISTORY endpoint
    const response = yield call(axios.get, TRANSACTIONHISTORY, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fetchTransactionHistorySuccess action with the response data as payload
      yield put(fetchTransactionHistorySuccess(response.data));
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchTransactionHistoryFailure action with the error message as payload
    yield put(fetchTransactionHistoryFailure(error.message));
  }
}

// Define a generator function TransactionHistorySaga
export function* TransactionHistorySaga() {
  // Use the takeLatest effect to listen for the FETCH_TRANSACTIONHISTORY_REQUEST action and run the fecthtransactionHistory generator function when it's dispatched
  yield takeLatest(FETCH_TRANSACTIONHISTORY_REQUEST, fecthtransactionHistory);
}

// Export the TransactionHistorySaga as the default export
export default TransactionHistorySaga;