// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the TVSCHEDULETIMEZONE constant from the utility/constants file
import { TVSCHEDULETIMEZONE } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the FECTH_TVSCHEDULETIME_ZONE_REQUEST action type and the fecthtvscheduletimezoneSuccess and fecthtvscheduletimezoneFailure action creators from the tvscheduletimezoneAction file
import {
  FECTH_TVSCHEDULETIME_ZONE_REQUEST,
  fecthtvscheduletimezoneSuccess,
  fecthtvscheduletimezoneFailure,
} from "../../actions/tvscheduletimezoneAction";

// Define a generator function fecthTvScheduletimezone that takes an action object as an argument
function* fecthTvScheduletimezone(action) {
  try {
    // Use the call effect to make a GET request to the TVSCHEDULETIMEZONE endpoint
    const response = yield call(axios.get, TVSCHEDULETIMEZONE, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fecthtvscheduletimezoneSuccess action with the response data as payload
      yield put(fecthtvscheduletimezoneSuccess(response.data));
    }
  } catch (error) {
    // If an error occurs, dispatch the fecthtvscheduletimezoneFailure action with the error message as payload
    yield put(fecthtvscheduletimezoneFailure(error.message));
  }
}

// Define a generator function TvScheduletimezoneSaga
export function* TvScheduletimezoneSaga() {
  // Use the takeLatest effect to listen for the FECTH_TVSCHEDULETIME_ZONE_REQUEST action and run the fecthTvScheduletimezone generator function when it's dispatched
  yield takeLatest(
    FECTH_TVSCHEDULETIME_ZONE_REQUEST,
    fecthTvScheduletimezone
  );
}

// Export the TvScheduletimezoneSaga as the default export
export default TvScheduletimezoneSaga;