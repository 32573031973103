// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { PARTNERID, STATICPAGE } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_DYNAMICPAGES_REQUEST,
  fetchDynamicPagesSuccess,
  fetchDynamicPagesFailure,
} from "../../actions/dynamicPagesActions";

function* fecthDynamicPages(action) {
  try {
    const dynamicpagePayload = new FormData();
    dynamicpagePayload.append("slug", action.payload.slug);
    dynamicpagePayload.append("partner_id", PARTNERID);


    const response = yield call(axios.post, STATICPAGE, dynamicpagePayload, {
      // headers: {
      //   token: localStorage.getItem("token"),
      // },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchDynamicPagesSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchDynamicPagesFailure(error.message));
  }
}

export function* DynamicPagesSaga() {
  yield takeLatest(FETCH_DYNAMICPAGES_REQUEST, fecthDynamicPages);
}
export default DynamicPagesSaga;
