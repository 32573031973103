// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { RELATEDVIDEOS } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_RELATEDVIDEOS_REQUEST,
  fecthRelatedVideosSuccess,
  fecthRelatedVideosFailure,
} from "../../actions/relatedVideosActions";

function* fecthRelatedVideo(action) {
  try {
    const response = yield call(axios.post, RELATEDVIDEOS, action.payload, {
      headers: {
        token: localStorage.getItem("token"),

      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fecthRelatedVideosSuccess(response.data));
    }
  } catch (error) {
    yield put(fecthRelatedVideosFailure(error.message));
  }
}

export function* relatedVideoSaga() {
  yield takeLatest(FETCH_RELATEDVIDEOS_REQUEST, fecthRelatedVideo);
}
export default relatedVideoSaga;
