// actions.js

// Action types
export const FETCH_MYLIST_REQUEST = "FETCH_MYLIST_REQUEST";
export const FETCH_MYLIST_SUCCESS = "FETCH_MYLIST_SUCCESS";
export const FETCH_MYLIST_FAILURE = "FETCH_MYLIST_FAILURE";

// Action creators
export const fetchMyListRequest = () => ({
  type: FETCH_MYLIST_REQUEST,
});

export const fetchMyListSuccess = (myList) => ({
  type: FETCH_MYLIST_SUCCESS,
  payload: myList,
});

export const fetchMyListFailure = (error) => ({
  type: FETCH_MYLIST_FAILURE,
  payload: error,
});
