// reducer.js

import {
  FETCH_SIDEMENU_REQUEST, // Import action type for requesting side menu data
  FETCH_SIDEMENU_SUCCESS, // Import action type for successful side menu data fetch
  FETCH_SIDEMENU_FAILURE, // Import action type for side menu data fetch failure
} from "../../actions/sidemenuActions"; // Import action types

// Initial state for the side menu data reducer
const initialState = {
  sideMenu: {}, // Initial side menu data is an empty object
  loading: false, // Initial loading state is false
  error: null, // Initial error state is null
};

// Reducer function to handle side menu data
const sideMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIDEMENU_REQUEST:
      // When requesting side menu data, set loading to true
      return {
        ...state,
        loading: true,
      };
    case FETCH_SIDEMENU_SUCCESS:
      // When side menu data fetch is successful, update sideMenu and set loading to false
      return {
        ...state,
        loading: false,
        sideMenu: action.payload, // Update side menu data with the fetched data
      };
    case FETCH_SIDEMENU_FAILURE:
      // When side menu data fetch fails, set loading to false and update error with the error message
      return {
        ...state,
        loading: false,
        error: action.payload, // Update error state with the error message
      };
    default:
      return state;
  }
};

export default sideMenuReducer;
