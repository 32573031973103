import { put, call, takeEvery } from "redux-saga/effects";
import {
  UPLOAD_IMAGE_REQUEST,
  uploadImageSuccess,
  uploadImageFailure,
} from "../../actions/imageUploadActions";
import axios from "axios";
import { IMAGEUPLOADER } from "../../../utility/constants.js";

function* uploaderImage(action) {
  try {
    const response = yield call(axios.post, IMAGEUPLOADER, action.payload, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    if (response.status === 200 && response.data.status === "Success") {
      yield put(uploadImageSuccess(response.data));
    }
  } catch (error) {
    yield put(uploadImageFailure(error.message));
  }
}

export function* imageUploadSaga() {
  yield takeEvery(UPLOAD_IMAGE_REQUEST, uploaderImage);
}
export default imageUploadSaga;
