import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
const LoaderSpinner = () => {
    return (
        <div className='spinner-loading'>
            <CircularProgress className='spinerLoader' />
        </div>
    )
}

export default LoaderSpinner