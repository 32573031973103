// Import the action type constants from the tvscheduleActions file
import {
  FECTH_TVSCHEDULE_REQUEST,
  FECTH_TVSCHEDULE_SUCCESS,
  FECTH_TVSCHEDULE_FAILURE,
} from "../../actions/tvscheduleActions";

// Define the initial state for the reducer
const initialState = {
  tvscheduleData: {}, // Initial value for tvscheduleData is an empty object
  isLoading: false, // Initial value for isLoading is false
  isError: null, // Initial value for isError is null
};

// Define the TvscheduleReducer function
const TvscheduleReducer = (state = initialState, action) => {
  // Use a switch statement to handle different action types
  switch (action.type) {
    // If the action type is FECTH_TVSCHEDULE_REQUEST
    case FECTH_TVSCHEDULE_REQUEST:
      // Return a new state object with isLoading set to true and isError set to null
      return { ...state, isLoading: true, isError: null };

    // If the action type is FECTH_TVSCHEDULE_SUCCESS
    case FECTH_TVSCHEDULE_SUCCESS:
      // Return a new state object with tvscheduleData set to the action payload, isLoading set to false, and isError set to null
      return { ...state, tvscheduleData: action.payload, isLoading: false, isError: null };

    // If the action type is FECTH_TVSCHEDULE_FAILURE
    case FECTH_TVSCHEDULE_FAILURE:
      // Return a new state object with isLoading set to false and isError set to the action payload
      return { ...state, isLoading: false, isError: action.payload };

    // If the action type doesn't match any of the above cases
    default:
      // Return the current state unchanged
      return state;
  }
};

// Export the TvscheduleReducer as the default export
export default TvscheduleReducer;