// sagas.js

// Import necessary functions and constants from redux-saga/effects and axios
import { takeLatest, put, call } from "redux-saga/effects";
import { PRAYER } from "../../../utility/constants";
import axios from "axios";

// Import action types and action creators related to prayer actions
import {
  FETCH_PRAYER_REQUEST,
  fetchPrayerSuccess,
  fetchPrayerFailure,
} from "../../actions/prayerActions";

// Generator function responsible for handling the prayer fetch action
function* fecthPrayer(action) {
  //  statement for debugging purposes


  try {
    // Create a new FormData object to hold prayer data
    const prayerFormData = new FormData();

    // Append prayer data fields to the FormData object
    prayerFormData.append("name", action.payload.name);
    prayerFormData.append("email", action.payload.email);
    prayerFormData.append("phone_no", action.payload.phone);
    prayerFormData.append("address", action.payload.address);
    prayerFormData.append("country", action.payload.country);
    prayerFormData.append("message", action.payload.message);

    // Make a POST request to the PRAYER endpoint using axios
    const response = yield call(axios.post, PRAYER, prayerFormData, {
      // Include token in the request headers retrieved from local storage
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // If the request is successful and the status is "Success", dispatch fetchPrayerSuccess action
    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchPrayerSuccess(response.data));
    }
  } catch (error) {
    // If an error occurs during the request, dispatch fetchPrayerFailure action with error message
    yield put(fetchPrayerFailure(error.message));
  }
}

// Generator function that watches for FETCH_PRAYER_REQUEST actions and calls fecthPrayer in response
export function* PrayerSaga() {
  yield takeLatest(FETCH_PRAYER_REQUEST, fecthPrayer);
}

// Export the PrayerSaga function as default
export default PrayerSaga;
